import React from 'react'
import { graphql } from 'gatsby'
import BlockHero from 'components/blocks/BlockHero'
import BlockAboutLogos from 'components/blocks/BlockAboutLogos'
import BlockCards from 'components/blocks/BlockCards'
import BlockContact from 'components/blocks/BlockContact'
import Footer from 'components/Footer'
import Seo from 'components/Seo'

const Page = ({ data }) => {
  const newsPosts = data.allPrismicNewsPost.nodes
  let content = {
    blockHero: {
      image: `image-our-kitchens.jpg`,
      textBlock: {
        isDark: true,
        text: {
          pretitle: '关于我们',
          title: `餐饮外卖共享厨房
          <span>“一站式”解决方案专家</span>`,
          description: `星选闪电是国内领先的餐饮外卖共享厨房品牌，提供“一站式”对接餐饮门店、外卖档口、餐饮运营及品牌孵化等服务。
          <span>与CloudKitchens®外卖厨房合作。</span>`,
        },
      },
    },
    aboutLogos: [
      {
        logo: 'logo-flash-kitchen-vertical.png',
        body: `<p>旗下产品包括星选闪电厨房，星选闪电食集，以及星选闪电福市。门店遍布北京、上海、深圳、广州、杭州、南京、成都等城市。已入驻星选闪电的知名餐饮品牌包括晨曦炖品、真功夫、吉野家、老娘舅、牛家人、四有青年、粉红汉堡、咕咕韩式炸鸡、凉皮先生等。
        <span style="display:none">与CloudKitchens®️外卖厨房合作。</span></p>`,
      },
    //   {
    //     logo: 'logo-foodie-wheel-vertical.png',
    //     body: `<p>星厨风车是国内领先的企业订餐平台，专注向企业用户提供员工餐、生日会、下午茶、活动餐、商户宴请等综合解决方案。</p>
    // <p>我们通过提供优质的餐饮服务，帮助企业提升员工的餐饮福利体验，改善员工满意度和工作幸福感，帮助企业留住优秀人才，创造更大的价值。</p>`,
    //   },
    ],
    cards: {
      title: '新闻',
      cards: newsPosts.map((post) => {
        return {
          link: `/about/${post.uid}/`,
          gatsbyImage: post.data.image,
          text: {
            pretitle: post.data.date,
            title: post.data.title.text,
            excerpt: post.data.excerpt.text,
          },
        }
      }),
    },
  }
  const seoContent = {
    title: `关于我们`,
  }
  return (
    <div>
      <Seo content={seoContent} />
      <BlockHero
        content={content.blockHero}
        mobileBackgroundClass='blue-to-grey'
      />
      <BlockAboutLogos content={content.aboutLogos} />
      <BlockCards content={content.cards} />
      <BlockContact />
      <Footer />
    </div>
  )
}

export default Page

export const query = graphql`
  query {
    allPrismicNewsPost(sort: { order: DESC, fields: data___date }) {
      nodes {
        uid
        data {
          date
          title {
            text
          }
          excerpt {
            text
          }
          image {
            gatsbyImageData
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
