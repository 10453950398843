import React from 'react'
import Image from 'components/Image'
import Inview from 'components/Inview'

const Component = ({ content }) => {
  return (
    <section className='block-about-logos bg--grey-2'>
      <div className='blocks--holder'>
        <div className='container'>
          {content.map((block, i) => (
            <Inview key={i} className='block fade-in-up'>
              <div className='logo'>
                <Image filename={block.logo} />
              </div>
              <div className='text'>
                <div
                  className='body'
                  dangerouslySetInnerHTML={{ __html: block.body }}
                />
              </div>
            </Inview>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Component
